import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import ProductCard from "../../Components/ProductCard";
import ProductFilter from "../../Components/ProductFilter";
import ProductFilterModal from "../../Components/ProductFilterModal";
import SearchBar from "../../Components/SearchBar";
import { placeHolderImages } from "../../config/constant";
import { getProducts } from "../../config/services";
import OrgInfoContext from "../../Contexts/OrgInfo.context";
import ProductsLoader from "../../Components/Loaders/ProductsLoader";
import './ProductListing.scss';
import ProductFilterNew from '../../Components/ProductFilterNew';
const ProductListing = (props) => {
    // console.log('[ProductListing.js] isFromCategoryDetails >>> ', props.isFromCategoryDetails);
    const { orgInfo, customerInfo } = useContext(OrgInfoContext);
    const [ allProducts, setAllProducts ] = useState([]);
    const [ pageInfo, setPageInfo ] = useState({});
    const [ isLoadingMore, setIsLoadingMore ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ currentSearchStr, setCurrentSearchStr ] = useState("");
    const [ filterType, setFilterType ] = useState(0);
    const [ propCategory_id, setPropCategory_id ] = useState(props?.category_id || 0);
    const [ propChild_category_id, setPropChild_category_id ] = useState(props?.child_category_id || 0);
    const [ dropDownData, setDropDownData ] = useState([
        {
            id: 0,
            name: "New to Old",
            isActive: true
        },
        {
            id: 1,
            name: "Old to New",
            isActive: false
        },
        {
            id: 2,
            name: "Design Name Asc",
            isActive: false
        },
        {
            id: 3,
            name: "Design Name Desc",
            isActive: false
        },
    ]);
    
    const [ filters, setFilters ] = useState({
        sort_by: 0,
        filter_tag: 0,
        min_price: '',
        max_price: '',
        tag_ids: '',
        color_ids: '',
        size_ids: '',
        is_stock: orgInfo.store_json && orgInfo.store_json.show_in_stock_only == 1 ? 1 : 2,
        is_greater_than: 0,
        greater_than_value: '',
        is_less_than: 0,
        less_than_value: ''
    })

    useEffect(() => {
        // console.log('[ProductListing.js] useEffect props.child_category_id ', props.child_category_id)
        if (props?.child_category_id != propChild_category_id) {
            setPageInfo({});
            setAllProducts([]);
            setPropChild_category_id(props?.child_category_id);
        }

    }, [ props?.child_category_id || 0 ]);



    const APIGetProducts = async () => {
        let reqBody = {
            "category_id": propCategory_id,
            // "sort_by": filterType,
            "child_category_id": propChild_category_id,
            "customer_id": customerInfo?.pk_customerOrgID || "",
            "search_string": currentSearchStr,
            "customer_category_id": customerInfo?.customerCategoryId || "",
            ...filters
        };
        const getProductsData = await getProducts(pageInfo, orgInfo.store_url, reqBody);
        // console.log('[ProductListing.js] getProductsData >', getProductsData);
        setIsLoading(false);
        if (getProductsData.status) {
            const existingData = [ ...allProducts, ...getProductsData.data ].map(ed => {
                ed.isDetailed = 1;
                return ed;
            });
            setAllProducts(existingData);
            setPageInfo(getProductsData.page);
            setIsLoadingMore(false);
        }
    };

    useEffect(async () => {
        setIsLoading(true);
        APIGetProducts();
    }, [ currentSearchStr, filters, propChild_category_id ]);

    const loadFunc = () => {
        // console.log('[ProductListing.js] loadFunc Called')
        if (!isLoadingMore && pageInfo?.next) {
            setIsLoadingMore(true);
            APIGetProducts();
        }
    };

    const searchHandle = (searchString = "") => {
        // console.log('[ProductListing.js] searchHandle searchString >' + searchString)
        if (searchString !== currentSearchStr) {
            setPageInfo({});
            setAllProducts([]);
            setCurrentSearchStr(searchString);
            // APIGetCategories();
        }
    };

    const filterHandle = (filter) => {
        // console.log('[ProductListing.js] filterHandle filter >', filter)
        // if (filter !== filterType) {
        setPageInfo({});
        setAllProducts([]);
        // setFilterType(filter);
        let tempDropDown = JSON.parse(JSON.stringify(dropDownData));
        tempDropDown = tempDropDown.map(td => {
            td.isActive = td.id == filter;
            return td;
        });
        setDropDownData(tempDropDown);
        setFilters(filter);
        // APIGetCategories();
        // }
    };

    return (
        <>
            {isLoading ? <ProductsLoader /> : null}
            {isLoading ?
                null : <SearchBar
                    isFromCategoryDetails={props?.isFromCategoryDetails}
                    currentSearchStr={currentSearchStr}
                    placeHolder={"Search For Designs"}
                    searchFunctionName={"searchHandle"}
                    searchHandle={searchHandle}
                />
            }
        <div className="row product-sub-container">
            <div className="col col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2 product-in-page-filters">
                <ProductFilterNew
                    functionName={"functionHandle"}
                    filterHandle={filterHandle}
                    dropDownData={dropDownData}
                    orgInfo={orgInfo}
                />
            </div>
            <section className="category-wrp product-listing-wrp col product-col">
                <div className="container-fluid">
                    <div className="products-head">
                        <div className="row">
                            <div className="col-md-7 col-sm-7">
                                <h2>{props?.subCategoryName || "All Products"}</h2>
                            </div>
                            {/* <ProductFilter functionName={"functionHandle"} functionHandle={filterHandle} dropDownData={dropDownData} /> */}
                            <ProductFilterModal
                                functionName={"functionHandle"}
                                filterHandle={filterHandle}
                                dropDownData={dropDownData}
                                orgInfo={orgInfo}
                            />


                        </div>
                    </div>
                    {(!isLoading && !allProducts.length) ?
                        <div className="not-found-block">
                            <div className="found-box">
                                <img src="/images/not-found-img.svg" alt="" />
                                <h4>No Product found</h4>
                                <p>There is no product added yet</p>
                                <br />
                                <br />
                            </div>
                        </div>
                        :
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={loadFunc}
                            hasMore={true}
                            threshold={50}
                            useWindow={true}
                            isReverse={false}
                        // loader={<div className="loader" key={0}>Loading ...</div>}
                        >
                            <div className="pro-lst-blks">
                                <div className="row">
                                    {allProducts.map((ap, ind) => {
                                        ap.image = (ap.design_images && ap.design_images.length) ? ap.design_images[ 0 ] : placeHolderImages.DESIGN;
                                        return (
                                            <div key={ind} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                                <div className="women-box">
                                                    <ProductCard productInfo={ap} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </InfiniteScroll>
                    }
                </div>
                {isLoadingMore ?
                    <div className="pro-lst-blks">
                        <div className="view-allpro">
                            <a className="view-btn">Loading ...</a>
                        </div>
                    </div>
                    :
                    null
                }
                {
                    allProducts.length ?
                        <>
                            <br />
                            <br />
                            <br />
                            <br />
                        </>
                        :
                        null
                }
            </section>
        </div>
        </>

    );

};
export default ProductListing;