import React, { useState, useEffect,useRef, useMemo } from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import { getOrgTags, getOrgColors, getOrgSizes, getOrgDesignMinMaxPrice } from "../config/services";
import SearchBarProductFilter from './SearchBarProductFilter';
import InfiniteScroll from 'react-infinite-scroller';
import MultiRangeSlider from "multi-range-slider-react";
import { cloneDeep } from 'lodash'
const defaultSortBy = 0; // latest designs
const ProductFilterNew = (props) => {
    const [ showFilterModal, setShowFilterModal ] = useState(false);
    const [ orgTagInfo, setOrgTagInfo ] = useState({ data: [] });
    const [ orgSizeInfo, setOrgSizeInfo ] = useState({ data: [] });
    const [ orgColorInfo, setOrgColorInfo ] = useState({ data: [] });
    const [ isLoading, setLoading ] = useState(false);
    const [ isLessThan, setIsLessThan ] = useState(false);
    const [ isgreaterThan, setIsgreaterThan ] = useState(false);
    const [ isInBetween, setIsInBetween ] = useState(false);
    const [ tagsSearchStr, setTagsSearchStr ] = useState('');
    const [ colorSearchStr, setColorSearchStr ] = useState('');
    const [ sizeSearchStr, setSizeSearchStr ] = useState('');
    const [ showClearAll, setShowClearAll ] = useState(false);
    const [ sortByType, setSortByType ] = useState(defaultSortBy);

    const tagSearchInputRef = useRef(null);
    const tagDropdownRef = useRef(null);

    const sizeSearchInputRef = useRef(null);
    const sizeDropdownRef = useRef(null);

    const colorSearchInputRef = useRef(null);
    const colorDropdownRef = useRef(null);

    const [ filters, setFilters ] = useState({
        sort_by: defaultSortBy,
        filter_tag: 0,
        min_price: 0,
        max_price: 0,
        tag_ids: [],
        color_ids: [],
        size_ids: [],
        is_stock: 2,
        is_greater_than: 0,
        greater_than_value: '',
        is_less_than: 0,
        less_than_value: ''
    });

    const [ sortByState, setSortByState ] = useState([
        {
            id: 0,
            name: "Latest Designs",
            isActive: true
        },
        {
            id: 2,
            name: "Design Name (A-Z)",
            isActive: false
        },
        {
            id: 3,
            name: "Design Name (Z-A)",
            isActive: false
        },
        {
            id: 4,
            name: "Price (Low- High)",
            isActive: false
        },
        {
            id: 5,
            name: "Price (High- Low)",
            isActive: false
        },
        {
            id: 6,
            name: "Stock (Low- High)",
            isActive: false
        },
        {
            id: 7,
            name: "Stock (High- Low)",
            isActive: false
        }
    ]);

    const [ stockByState, setStockByState ] = useState([
        {
            id: 2,
            name: "All",
            isActive: true
        },
        {
            id: 1,
            name: "In Stock",
            isActive: false
        },
        {
            id: 0,
            name: "Out of Stock",
            isActive: false
        }
    ]);

    const [viewTagsList, setViewTagsList] = useState(false);
    const [viewSizeList, setViewSizeList] = useState(false);
    const [viewColorList, setViewColorList] = useState(false);

    const [maxPrice, setMaxPrice] = useState(10000);
    const [minPrice, setMinPrice] = useState(0);
    const [intialMinMaxPriceRange, setIntialMinMaxPriceRange] = useState({ minPrice: 0, maxPrice: 10000});
    const [stockOptionValue, setStockOptionValue] = useState('');
    const [filtersList, setFilterListNav] = useState([
        { title: 'Sort By', activeCount: 0 },
        { title: 'Tags', activeCount: 0 },
        { title: 'Size', activeCount: 0 },
        { title: 'Colors', activeCount: 0 },
        { title: 'Stock', activeCount: 0 },
        { title: 'Price Range', activeCount: 0 }
    ]);

    useEffect(() => {
        if(props.orgInfo.store_json && props.orgInfo.store_json.show_in_stock_only == 1) {
            handleFilterUpdate('is_stock',1);
        }
        getAllFiltersData();
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            //console.log('[useEffect]handleClickOutside--');
          if (
            tagSearchInputRef.current &&
            !tagSearchInputRef.current.contains(event.target) &&
            tagDropdownRef.current &&
            !tagDropdownRef.current.contains(event.target)
          ) {
            setViewTagsList(false);
          }

          if (
            sizeSearchInputRef.current &&
            !sizeSearchInputRef.current.contains(event.target) &&
            sizeDropdownRef.current &&
            !sizeDropdownRef.current.contains(event.target)
          ) {
            setViewSizeList(false);
          }

          if (
            colorSearchInputRef.current &&
            !colorSearchInputRef.current.contains(event.target) &&
            colorDropdownRef.current &&
            !colorDropdownRef.current.contains(event.target)
          ) {
            setViewColorList(false);
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(()=> {
        updateFilterActiveCounts();
    },[filters.tag_ids.length, filters.size_ids.length, filters.color_ids.length]);

    useEffect(()=> {
        const hasChanges = hasChangesInFilter();
        setShowClearAll(hasChanges);
        //console.log('hasChanges-->>>>>', hasChanges);
    })

    useEffect(()=> {
        clearAllFilters();
    }, [window.location.href])

    const { organization_id } = props.orgInfo;

    const handleFilterUpdate = (filterName, value) => {
        //console.log('handleFilterUpdate---->>>filterName',filterName,value);
        setFilters({ ...filters, [ filterName ]: value })
    }

    const handleTagSelection = (id, keyName) => {
        //console.log('handleSelection--->>> keyName', keyName, id);
        const isIncludes = filters[ keyName ].includes(id);
        //console.log('isIncludes-->>', isIncludes);
        if (!isIncludes) {
            filters[ keyName ].push(id);
            setFilters({ ...filters });
            // orgTagInfo.content.map(tag=> {
            //     tag.selected = true;
            // })
            // setOrgTagInfo({...orgTagInfo, content: orgTagInfo.content });
        } else {
            setFilters({ ...filters, [ keyName ]: filters[ keyName ].filter(t => t !== id) })
        }
    }

    const searchHandle = async (type, searchText) => {
        //console.log('[CategoriesList.js] searchHandle searchString ----->>>>>',type, searchText)
        // if (currentSearchStr) {
        //     setOrgTagInfo({ content: [] });
        //     setCurrentSearchStr(currentSearchStr);
        //     setLoading(true);
        // }

        switch (type) {
            case 1:
                getAllTags(searchText);
                break;
            case 2:
                getAllSizes(searchText);
                break;
            case 3:
                getAllColors(searchText);
                break;
            default:
                break;
        }
    };

    const sortByContent = useMemo(() => {
        return (<ListGroup defaultActiveKey="#link1">
            {sortByState.map((s, index) =>
                <ListGroup.Item className="filter-sortby-items" key={index} action onClick={() => setSortByType(s.id)} >
                    <input className="me-2" type="checkbox" checked={s.id === sortByType} onChange={() => { }} />{s.name}
                </ListGroup.Item>)
            }
        </ListGroup>)
    }, [sortByType]);

    const handleOnScroll = (apiFun, dataObj) => {
        //console.log('handleOnScroll--->>>');
        if (dataObj && dataObj.page && dataObj.page.next && !isLoading) {
            apiFun();
        }
    }

    const inputClickHandler = (callBack)=> {
        //console.log('inputClickHandler--....');
        callBack(true);
    }

    const tagsContent = () => {
    
            return (
                <>
                    <SearchBarProductFilter
                        currentSearchStr={tagsSearchStr}
                        setCurrentSearchStr={setTagsSearchStr}
                        placeHolder={"Search tag"}
                        searchFunctionName={"searchHandle"}
                        searchHandle={(searchText) => searchHandle(1, searchText)}
                        inputClickHandler={()=> inputClickHandler(setViewTagsList)}
                        inputRef={tagSearchInputRef}
                        setData={setOrgTagInfo}
                    />
                    
                    <ListGroup ref={tagDropdownRef} defaultActiveKey="#link2" className="product-fil-modal-scroll shadow" style={{display: viewTagsList ? 'block': 'none'}}>
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={() => handleOnScroll(getAllTags, orgTagInfo)}
                            hasMore={orgTagInfo.page && orgTagInfo.page.next ? true : false}
                            threshold={10}
                            useWindow={false}
                            isReverse={false}
                        // loader={<div className="loader" key={0}>Loading ...</div>}                        
                        >
                            {orgTagInfo.data.map((s, idx) =>
                                <ListGroup.Item key={idx} action onClick={() => handleTagSelection(s.pk_tagID, 'tag_ids')} >
                                    <input type="checkbox" checked={filters.tag_ids && filters.tag_ids.includes(s.pk_tagID)} onChange={() => { }} /> {s.tagName} 
                                </ListGroup.Item>)
                            }
                            
                            {orgTagInfo.data.length == 0 && !isLoading && 
                            <ListGroup defaultActiveKey="#link1">
                                <ListGroup.Item >No Tags</ListGroup.Item>
                            </ListGroup>}

                            {
                            isLoading && 
                                <ListGroup defaultActiveKey="#link1">
                                    <ListGroup.Item >Loading ...</ListGroup.Item>
                                </ListGroup>
                            }
                            
                        </InfiniteScroll>
                    </ListGroup>
                </>
            )
    }

    const sizeContent = () => {

            return (
                <>
                    <SearchBarProductFilter
                        currentSearchStr={sizeSearchStr}
                        setCurrentSearchStr={setSizeSearchStr}
                        placeHolder={"Search size"}
                        searchFunctionName={"searchHandle"}
                        searchHandle={(searchText) => searchHandle(2, searchText)}
                        inputRef={sizeSearchInputRef}
                        inputClickHandler={()=> inputClickHandler(setViewSizeList)}
                        setData={setOrgSizeInfo}
                    />
                    <ListGroup ref={sizeDropdownRef} defaultActiveKey="#link2" className="product-fil-modal-scroll" style={{display: viewSizeList ? 'block': 'none'}}>
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={() => handleOnScroll(getAllSizes, orgSizeInfo)}
                            hasMore={orgSizeInfo.page && orgSizeInfo.page.next ? true : false}
                            threshold={10}
                            useWindow={false}
                            isReverse={false}
                        // loader={<div className="loader" key={0}>Loading ...</div>}                        
                        >

                            {orgSizeInfo.data.map((s, idx) =>
                                <ListGroup.Item key={idx} action onClick={() => handleTagSelection(s.size_id, 'size_ids')} >
                                    <input type="checkbox" checked={filters.size_ids && filters.size_ids.includes(s.size_id)} onChange={() => { }} /> {s.size_name}
                                </ListGroup.Item>)
                            }
                             {orgSizeInfo.data.length == 0 && !isLoading && 
                            <ListGroup defaultActiveKey="#link1">
                                <ListGroup.Item >No Sizes</ListGroup.Item>
                            </ListGroup>}

                            {
                        isLoading && 
                            <ListGroup defaultActiveKey="#link1">
                                <ListGroup.Item >Loading ...</ListGroup.Item>
                            </ListGroup>
                        }
                        </InfiniteScroll>
                    </ListGroup>
                </>
            )

        // return (<ListGroup defaultActiveKey="#link1">
        //     {<ListGroup.Item >No sizes</ListGroup.Item>}
        // </ListGroup>);
    }

    const colorContent = () => {
       
        return (
            <>
                <SearchBarProductFilter
                    currentSearchStr={colorSearchStr}
                    setCurrentSearchStr={setColorSearchStr}
                    placeHolder={"Search colors"}
                    searchFunctionName={"searchHandle"}
                    searchHandle={(searchText) => searchHandle(3, searchText)}
                    inputRef={colorSearchInputRef}
                    inputClickHandler={()=> inputClickHandler(setViewColorList)}
                    setData={setOrgColorInfo}
                />
                <ListGroup ref={colorDropdownRef} defaultActiveKey="#link2" className="product-fil-modal-scroll" style={{display: viewColorList ? 'block': 'none'}}>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={() => handleOnScroll(getAllColors, orgColorInfo)}
                        hasMore={orgColorInfo.page && orgColorInfo.page.next ? true : false}
                        threshold={10}
                        useWindow={false}
                        isReverse={false}
                    // loader={<div className="loader" key={0}>Loading ...</div>}                        
                    >
                        {orgColorInfo.data.map((s, idx) =>
                            <ListGroup.Item key={idx} action onClick={() => handleTagSelection(s.color_id, 'color_ids')} >
                                <input type="checkbox" checked={filters.color_ids && filters.color_ids.includes(s.color_id)} onChange={() => { }} /> {s.color_name}
                            </ListGroup.Item>)
                        }
                        {orgColorInfo.data.length == 0 && !isLoading && 
                            <ListGroup defaultActiveKey="#link1">
                                <ListGroup.Item >No tags</ListGroup.Item>
                            </ListGroup>}

                        {
                        isLoading && 
                            <ListGroup defaultActiveKey="#link1">
                                <ListGroup.Item >Loading ...</ListGroup.Item>
                            </ListGroup>
                        }
                    </InfiniteScroll>
                </ListGroup>
            </>
        )
    }

    const stockFilterSelectionHandler = (key) => {
        //console.log('stockFilterSelectionHandler',key);
        setStockOptionValue(key);
        switch (key) {
            case 'is_greater_than':
                setFilters({
                    ...filters,
                    is_greater_than: 1,
                    greater_than_value: '',
                    is_less_than: 0,
                    less_than_value: ''
                });
                setIsgreaterThan(true);
                setIsLessThan(false);
                setIsInBetween(false);
                break;
            case 'is_less_than':
                setFilters({
                    ...filters,
                    is_greater_than: 0,
                    greater_than_value: '',
                    is_less_than: 1,
                    less_than_value: ''
                });
                setIsgreaterThan(false);
                setIsLessThan(true);
                setIsInBetween(false);
                break;
            case 'is_in_between':
                setFilters({
                    ...filters,
                    is_greater_than: 1,
                    greater_than_value: '',
                    is_less_than: 1,
                    less_than_value: ''
                });

                setIsgreaterThan(false);
                setIsLessThan(false);
                setIsInBetween(true);
                break;
            default:
                setFilters({
                    ...filters,
                    is_greater_than: 0,
                    greater_than_value: '',
                    is_less_than: 0,
                    less_than_value: ''
                });
                setIsgreaterThan(false);
                setIsLessThan(false);
                setIsInBetween(false);
                break;
        }

    }

    const stockContent = useMemo(() => {
        // const options = ()=> [
        //     {id: 0, name: 'is great'},
        //     {id: 1, name: 'is lessor'},
        // ]
        //console.log('stockContent-->>>>', filters.is_greater_than);
        return (
            <div>
                <ListGroup defaultActiveKey="#link1" >
                    {stockByState.map((s, index) =>
                        <ListGroup.Item className="filter-stock-items" key={index} action onClick={() => handleFilterUpdate('is_stock', s.id)} >
                            <input className="me-2" type="checkbox" checked={s.id === filters.is_stock} /> {s.name} 
                        </ListGroup.Item>)
                    }
                </ListGroup>

                <label htmlFor="stock_value" className="mt-3 mb-2"><b>Stock Value</b></label>
                <div></div>
                <select
                    className="form-select mb-3"
                    name="stock_value"
                    id="stock_value" 
                    onChange={(event) => stockFilterSelectionHandler(event.target.value)}
                    value={stockOptionValue}
                >
                    <option value="">Select Option</option>
                    <option value="is_greater_than">Is Greater Than</option>
                    <option value="is_less_than">Is Less Than</option>
                    <option value="is_in_between">In Between</option>
                </select>
                <Row>

                    {isgreaterThan || isInBetween ?
                        <Col>
                            <label htmlFor="is_greater_than" className="mb-1">Greater than</label>
                            <input type="text" id='is_greater_than' value={filters.greater_than_value} 
                                onChange={(e)=>{ setFilters({ ...filters ,greater_than_value: isNaN(e.target.value) || e.target.value == ''? '' : Number(e.target.value)}) } 
                                }
                                className="form-control"
                            /> 
                        </Col>
                        : null
                    }

                    {isLessThan || isInBetween ?
                        <Col>
                            <label htmlFor="is_less_than" className="mb-1">Less than</label>
                            <input type="text" id='is_less_than' value={filters.less_than_value} 
                                onChange={(e)=> setFilters( { ...filters, less_than_value: isNaN(e.target.value) || e.target.value == ''? '' : Number(e.target.value)})}
                                className="form-control"
                            /> 
                        </Col>: null
                    }
                </Row>
               
            </div>
        )
    }, [filters.is_stock, isgreaterThan, isInBetween, isLessThan, isInBetween, filters.less_than_value, filters.greater_than_value])

    const priceRange = useMemo(() => {
        return (
        <div>
            <div className="form-group mt-2 row"  >
                <div className="col-6">
                    <label htmlFor="min_price" className="ps-0 form-label">Min Price</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id='min_price' 
                        style={{ width: '100%' }} 
                        value={minPrice} 
                        onChange={(e)=> setMinPrice(e.target.value)}
                    />
                </div>
                <div className="col-6">
                    <label htmlFor="max_price" className="ps-0 form-label">Max Price</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id='max_price' 
                        style={{ width: '100%' }} 
                        value={maxPrice}
                        onChange={(e)=> setMaxPrice(e.target.value)}
                    />
                </div>
            </div>

            <div
                className="multi-range-slider-container"
             >
                <MultiRangeSlider
                    min={intialMinMaxPriceRange.minPrice}
                    max={intialMinMaxPriceRange.maxPrice}
                    minValue={minPrice}
			        maxValue={maxPrice}
                    canMinMaxValueSame={true}
                    onChange={(e) => {
                        //console.log('e.minValue-->>',e.minValue);
                        //console.log('e.maxValue-->>',e.maxValue);
                        setMaxPrice(e.maxValue);
                        setMinPrice(e.minValue);
                    }}
                    
                    label={false}
                    ruler={false}
                    style={{ border: "none", boxShadow: "none", padding: "30px 10px" }}
                    //   barLeftColor="red"
                      barInnerColor="#1e9acb"
                    //   barRightColor="blue"
                    //   thumbLeftColor="lime"
                    //   thumbRightColor="lime"
                />
            </div>
        </div>)
    },[maxPrice, minPrice, intialMinMaxPriceRange])
 
    const getAllFiltersData = () => {
        getAllTags();
        getAllColors();
        getAllSizes();
        getOrgMinMaxPrice();
    }

    const getAllTags = async (searchText) => {
        //console.log('[FilterMOdal.js] getAllTags1 >', orgTagInfo);
        //console.log('[FilterMOdal.js] getAllTags12 >', tagsSearchStr);
        //console.log('[FilterMOdal.js] getAllTags12 >', searchText);

        let data = {
            filter_type: filters.sort_by,
            search_string: searchText ? searchText.trim() : (tagsSearchStr ? tagsSearchStr.trim() : ''),
            organization_id: organization_id,
        };
        setLoading(true);
        const tagsRes = await getOrgTags(orgTagInfo, data);
        //console.log('[FilterMOdal.js] getAllTags2 >', tagsRes);
        if (tagsRes.status) {
            setOrgTagInfo({ ...tagsRes, data: [ ...orgTagInfo.data, ...tagsRes.data ] });
        }
        setLoading(false);
    }

    const getAllColors = async (searchText) => {
        let data = {
            filter_type: filters.sort_by,
            search_string: searchText ? searchText.trim() : (colorSearchStr ? colorSearchStr.trim() : ''),
            organization_id: organization_id,
        };
        setLoading(true);
        const colorsRes = await getOrgColors(orgColorInfo, data);
        //console.log('[ProductListing.js] getAllColors >', colorsRes);
        if (colorsRes.status) {
            setOrgColorInfo({ ...colorsRes, data: [ ...orgColorInfo.data, ...colorsRes.data ] });
        }
        setLoading(false);
    }

    const getAllSizes = async (searchText) => {
        //console.log('[ProductListing.js] getAllSizes 11>', orgSizeInfo);

        let data = {
            filter_type: filters.sort_by,
            search_string: searchText ? searchText.trim() : (sizeSearchStr ? sizeSearchStr.trim() : ''),
            organization_id: organization_id,
        };
        setLoading(true);
        const sizesRes = await getOrgSizes(orgSizeInfo, data);
        //console.log('[ProductListing.js] getAllSizes >res', sizesRes);

        if (sizesRes.status) {
            setOrgSizeInfo({ ...sizesRes, data: [ ...orgSizeInfo.data, ...sizesRes.data ] });
        }
        setLoading(false);
    }

    const getOrgMinMaxPrice = async () => {
        let data = {
            organization_id: organization_id
        };
        setLoading(true);
        const minMaxPriceRes = await getOrgDesignMinMaxPrice(data);

        if (minMaxPriceRes.status) {
            const maxPrice = isNaN(minMaxPriceRes.data.max_price) ? '' : Math.ceil(minMaxPriceRes.data.max_price);
            const minPrice = isNaN(minMaxPriceRes.data.min_price) ? '' : Math.ceil(minMaxPriceRes.data.min_price);
            setMaxPrice(maxPrice);
            setMinPrice(minPrice);
            setIntialMinMaxPriceRange({ minPrice, maxPrice});
        }

        setLoading(false);
    }


    const onModalCloseHandler = () => {
        setShowFilterModal(false);
        setOrgTagInfo({ data: [] });
        setOrgColorInfo({ data: [] });
        setOrgSizeInfo({ data: [] });
    }

    const onSubmitHandler = () => {
        console.log('onSubmitHandler--->>', filters);
        props.filterHandle({
            ...filters,
            tag_ids: filters.tag_ids?.toString(),
            color_ids: filters.color_ids?.toString(),
            size_ids: filters.size_ids?.toString(),
            min_price: minPrice,
            max_price: maxPrice,
            sort_by: sortByType,
            is_greater_than: isNaN(filters.greater_than_value) || filters.greater_than_value === '' ? 0 : 1,
            is_less_than:  isNaN(filters.less_than_value) || filters.less_than_value === '' ? 0 : 1,
        });
        setTagsSearchStr('');
        setColorSearchStr('');
        setSizeSearchStr('');
        setShowFilterModal(false);
        console.log('onSubmitHandler--->>after update', filters);

    }

    const clearAllFilters = () => {
        //console.log('clearFilter--->>', filters);
        setShowClearAll(false);
        const resetFilters = {
            sort_by: defaultSortBy,
            filter_tag: 0,
            min_price: 0,
            max_price: 0,
            tag_ids: [],
            color_ids: [],
            size_ids: [],
            is_stock: props.orgInfo.store_json && props.orgInfo.store_json.show_in_stock_only == 1 ? 1 : 2,
            is_greater_than: 0,
            greater_than_value: '',
            is_less_than: 0,
            less_than_value: ''
        };

        setFilters({ ...resetFilters });
        props.filterHandle({
            ...resetFilters,
            tag_ids: '',
            color_ids: '',
            size_ids: ''
        });

        setStockOptionValue('');
        setIsInBetween(false);
        setIsLessThan(false);
        setIsgreaterThan(false);
        setSortByType(defaultSortBy);
        setShowFilterModal(false);
    }

    const updateFilterActiveCounts = ()=> {
        const filtersT = cloneDeep(filtersList);
        filtersT[1].activeCount = filters.tag_ids && filters.tag_ids.length ? filters.tag_ids.length : 0;
        filtersT[2].activeCount = filters.size_ids && filters.size_ids.length ? filters.size_ids.length : 0;
        filtersT[3].activeCount = filters.color_ids && filters.color_ids.length ? filters.color_ids.length : 0;
        setFilterListNav([...filtersT])
    }

    const hasChangesInFilter = () => {
        // Check if both objects have the same keys
        const obj1 = filters;
        const obj2 = {
            sort_by: defaultSortBy,
            filter_tag: 0,
            min_price: 0,
            max_price: 0,
            tag_ids: [],
            color_ids: [],
            size_ids: [],
            is_stock: props.orgInfo.store_json && props.orgInfo.store_json.show_in_stock_only == 1 ? 1 : 2,
            is_greater_than: 0,
            greater_than_value: '',
            is_less_than: 0,
            less_than_value: ''
        };

        const keys1 = Object.keys(filters);
        
        if(sortByType != 2) return true;

        for (const key of keys1) {
            // If the key is not present in the second object
            if (!obj2.hasOwnProperty(key)) {
                return true;
            }

            // Special handling for arrays

            if (Array.isArray(obj1[key])) {
                if (obj1[key].length) {
                    return true;
                }
            } else {
                if(obj1[key] != obj2[key]) {
                    return true;
                }
            }
            
        }
    
        return false;
    }

    const saveButtonStyle = {
        backgroundColor: "#021029",
        color: "white",
        width: "107px",
        borderRadius: "20px",
        fontWeight: 'bold'
    }
    
    const saveButtonStyle2 = {
        backgroundColor: 'rgb(2 16 41 / 85%)',
        color: 'white',
        width: '79px',
        borderRadius: '20px',
        fontWeight: 'bold',
        height: '30px'
    }

    return (
        <div className="" style={{fontSize:'small'}}>
            <div className="filter-item">
                <div className="align-items-center d-flex justify-content-between">
                        <h4 style={{margin:'0px'}} ><b>FILTERS</b></h4> 
                        { true ||showClearAll ? 
                            <span onClick={()=> clearAllFilters()}
                                style={{
                                    // border: '1px solid #cecbcb', 
                                    borderRadius: '19px', cursor: 'pointer', padding:'5px', color: 'red', fontWeight: '600'
                                }}
                                className="px-3"
                            >CLEAR ALL <i class="fa fa-times"></i>
                        </span> : null} 
                        
                </div>
                <div className="me-3 mt-2 text-end">
                    <button className="btn btn-sm" onClick={onSubmitHandler} style={saveButtonStyle2} >SAVE</button>
                </div>
            </div>
           
            <div className="filter-item">
                <h6><strong>SORT BY</strong></h6>
                {sortByContent}
            </div>
            <div className="filter-item">
                <h6><strong>TAGS</strong>  {filtersList[1].activeCount > 0 ? <span className="filter-checked-item-count">{filtersList[1].activeCount} </span>: ''}</h6>
                {tagsContent()} 
            </div>
            <div className="filter-item">
                <h6><strong>SIZE</strong>  {filtersList[2].activeCount > 0 ? <span className="filter-checked-item-count">{filtersList[2].activeCount} </span>: ''}</h6>
                {sizeContent()}
            </div>
            <div className="filter-item">
                <h6><strong>COLORS</strong>  {filtersList[3].activeCount > 0 ? <span className="filter-checked-item-count">{filtersList[3].activeCount} </span>: ''}</h6>
                {colorContent()}
            </div>

            <div className="filter-item">
                <h6><strong>STOCK</strong> </h6>
                {stockContent}
            </div>
            <div className="filter-item border-bottom-0">
                <h6><strong>PRICE RANGE</strong></h6>
                {priceRange}
            </div>
            <div className="text-center">
                <button className="btn" onClick={onSubmitHandler} style={saveButtonStyle} >SAVE</button>
            </div>
        </div>
    );
};

export default ProductFilterNew;