import { Modal, Badge, Tooltip, OverlayTrigger } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import OrgInfoContext from "../Contexts/OrgInfo.context";
import { getProductDetails, saveCart } from "../config/services";
import { placeHolderImages } from "../config/constant";
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash'

const ProductQtyModalNew = () => {
    const {
        setProductCardActiveInfo,
        productQtyModelDesId,
        setProductQtyModelDesId,
        setFullBgBlur,
        orgInfo,
        customerInfo,
        copiedSizeQty,
        setCopiedSizeQty
    } = useContext(OrgInfoContext);
    const [loader, setLoader] = useState(false);
    const [isShowCopyModal, setIsShowCopyModal] = useState(null);
    const [isCopyModelFromColorWise, setIsCopyModelFromColorWise] = useState(false);
    const [designInfo, setDesignInfo] = useState(null);
    const [selectedColorInfo, setSelectedColorInfo] = useState([]);
    const [selectedSizesInfo, setSelectedSizesInfo] = useState([]);
    const [sizesModalSizeInfo, setSizesModalSizeInfo] = useState([]);
    const [isShowSizesModal, setIsShowSizesModal] = useState(false);
    const [sizeTotalQty, setSizeTotalQty] = useState(0);
    const [sizeTotalAmt, setSizeTotalAmt] = useState(0);
    const [isPastedCopiedQty, setIsPastedCopiedQty] = useState(false);
    const [activeColorInex, setActiveColorInex] = useState(0);

    // sizes modal
    const [isAllSelectSizesOption, setIsAllSelectSizesOption] = useState(false);
    const [allSelectSizesInpQty, setAllSelectSizesInpQty] = useState(0);
    const [sizeModalTotalQty, setSizeModalTotalQty] = useState(0);
    const [sizeModalTotalAmt, setSizeModalTotalAmt] = useState(0);

    useEffect(async () => {
        if (productQtyModelDesId) {
            setLoader(true);
            setFullBgBlur(true);
            try {
                const productInfo = await getProductDetails(orgInfo.store_url, productQtyModelDesId.designId, customerInfo?.pk_customerOrgID || "", productQtyModelDesId.categoryId, customerInfo.customerCategoryId);
                console.log('[ProductQtyModal.js] useEffect productInfo>', productInfo);
                if (productInfo.status) {
                    setDesignInfo(productInfo.data);

                    if (Array.isArray(productInfo?.data?.color_size_data)) {
                        const colors = productInfo?.data?.color_size_data.map(color => {
                            const t = { ...color };
                            // delete t.size_info;
                            return t;
                        });
                        setSelectedColorInfo(cloneDeep(colors));
                    }

                    if (Array.isArray(productInfo?.data?.color_size_data) && productInfo?.data?.color_size_data.length) {
                        console.log('---->>>>>>>>>>>>>', productInfo?.data?.color_size_data);
                        setSelectedSizesInfo(cloneDeep(productInfo?.data?.color_size_data[0].size_info)) // main sizes
                        setSizesModalSizeInfo(cloneDeep(productInfo?.data?.color_size_data[0].size_info)); // secondry modal sizes 
                    }

                }
            } catch (error) {
                console.error('[ProductQtyModal.js] Use Effect Exception >', error);
            } finally {
                setLoader(false);
                setFullBgBlur(false);
            }
        }

        return () => {
            setLoader(false);
            setProductQtyModelDesId(null);
            setDesignInfo(null);
            setFullBgBlur(false);
        };
    }, [productQtyModelDesId]);
    const onHideHandler = () => {
        setProductQtyModelDesId(null);
        setDesignInfo(null);
        setFullBgBlur(false);
    };

    // for sizes modal
    useEffect(() => {
        if (sizesModalSizeInfo) {
            let totalQty = 0;
            let totalAmt = 0;
            sizesModalSizeInfo.forEach(s => {
                if (s.isSelected) {
                    totalQty += s.quantity;
                    totalAmt += (s.quantity * s.size_rate);
                }
            });

            setSizeModalTotalQty(totalQty);
            setSizeModalTotalAmt(totalAmt);
        }
    }, [sizesModalSizeInfo])

    // main modal
    useEffect(() => {
        if (selectedColorInfo) {
            let totalQty = 0;
            let totalAmt = 0;
            console.log('useEffect((---->>>', selectedColorInfo);
            selectedColorInfo.forEach(color => {
                color?.size_info?.forEach(s => {
                    // if(s.isSelected) {
                    totalQty += s.quantity;
                    const rate = designInfo?.list_type == 1 || designInfo?.list_type == 2 ? designInfo?.sale_price : s.size_rate;
                    totalAmt += (s.quantity * rate);
                    if (s.quantity) s.isSelected = true;
                    // }
                })
            })
            setSizeTotalQty(totalQty);
            setSizeTotalAmt(totalAmt);
        }
    }, [selectedColorInfo])

    /** size list secondry modal */
    const onChangeHandler = (qty, sizeIndex = null) => {
        if(qty < 0) return;
        if (sizesModalSizeInfo) {
            const temp = cloneDeep(sizesModalSizeInfo);

            if (sizeIndex != null) {
                if(temp[sizeIndex].is_stock_available) {
                    temp[sizeIndex].quantity = parseFloat(qty) * 1;
                    temp[sizeIndex].isSelected = true;
                }
            } else {
                if (isAllSelectSizesOption) {
                    temp.forEach(s => {
                        if(s.is_stock_available) {
                            s.quantity = parseFloat(qty) * 1;
                            s.isSelected = true;
                        }
                    })
                }
                setAllSelectSizesInpQty(qty);
            }

            setSizesModalSizeInfo(temp);
        }
    };

    /** main modal no color no size*/
    const onChangeHandlerMain = (qty, sizeIndex = null) => {
        if (selectedColorInfo[activeColorInex] && selectedColorInfo[activeColorInex]?.size_info?.length) {
            const temp = selectedColorInfo[activeColorInex].size_info;

            if (sizeIndex != null) {
                temp[sizeIndex].quantity = parseFloat(qty) * 1;
                temp[sizeIndex].isSelected = true;
            } else {
                if (isAllSelectSizesOption) {
                    temp.forEach(s => {
                        s.quantity = parseFloat(qty) * 1;
                        s.isSelected = true;
                    })
                }
                setAllSelectSizesInpQty(qty);
            }

            setSelectedColorInfo([...selectedColorInfo]);
        }
    };

    const allSizeSelectionHandler = (isChecked, index = null) => {
        if (sizesModalSizeInfo) {
            const temp = cloneDeep(sizesModalSizeInfo);

            if (index != null) {
                temp[index].isSelected = isChecked;
            } else {
                temp.forEach(s => {
                    if(s.is_stock_available) {
                        s.quantity = isChecked ? (parseFloat(allSelectSizesInpQty) * 1) : 0;
                        s.isSelected = isChecked;
                    }
                });

                if (!isChecked) {
                    setAllSelectSizesInpQty(0);
                }

                setIsAllSelectSizesOption(isChecked);
            }

            setSizesModalSizeInfo(temp);
        }
    }

    const onSelectColor = (isSelected, index) => {
        if (selectedColorInfo) {
            const temp = cloneDeep(selectedColorInfo);
            temp[index].isSelected = true;
            setSelectedColorInfo(temp);
        }

        setActiveColorInex(index);
    }

    const onSaveSizeModalHandler = () => {
        // setSelectedSizesInfo(cloneDeep(sizesModalSizeInfo));
        const tempArry = sizesModalSizeInfo.map(s => ({ ...s, quantity: s.isSelected ? s.quantity : 0 }))
        selectedColorInfo[activeColorInex].size_info = cloneDeep(tempArry);
        setIsShowSizesModal(false);
        setFullBgBlur(false);
        setSelectedColorInfo([...selectedColorInfo])
    }

    const onHideSizeModalHandler = () => {
        setIsShowSizesModal(false);
        setFullBgBlur(false);
    };

    /** end */
    const checkIsQtyThere = () => {
        let isQty = 0;
        for (const col of designInfo?.color_size_data || []) {
            if (isQty) {
                break;
            }
            for (const sz of col?.size_info || []) {
                if (sz?.quantity && parseFloat(sz.quantity) > 0) {
                    isQty = 1;
                    return 1;
                    break;
                }
            }
        }
        return isQty;
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        designInfo.color_size_data = selectedColorInfo;
        try {
            // Check if qty exists or not
            if (!checkIsQtyThere()) {
                toast.warn('Please Enter Qty To Proceed Further');
                return false;
            }
            // if(designInfo.color_size_data && designInfo.color_size_data.length) {
            //     designInfo.color_size_data.forEach(color=> {

            //     })
            // }
            const saveToCart = await saveCart(orgInfo.store_url, 0, customerInfo.pk_customerOrgID, designInfo.design_id, designInfo.color_size_data, designInfo.list_type);
            if (saveToCart?.status) {
                if (saveToCart?.data?.message) {
                    toast.success(saveToCart?.data?.message || "Cart Updated Successfully...");
                    setProductCardActiveInfo({
                        designId: designInfo?.design_id,
                        isActive: 1
                    });
                    onHideHandler();
                }
            } else {
                throw saveToCart;
            }
        } catch (error) {
            console.error('[ProductQtyModal.js] > onSubmitHandler error', error);
        }
    };
    // old
    const copyQty = (event, colorInd, isFromColorWise = false) => {
        // console.log('[ProductQtyModal.js] copyQty colorId >' + colorInd);
        setIsShowCopyModal(colorInd);
        setIsCopyModelFromColorWise(isFromColorWise);
    };

    const copySubmitHandler = () => {
        // console.log('[ProductQtyModal.js] copySubmitHandler isShowCopyModal >' + isShowCopyModal);
        if (isShowCopyModal !== null) {
            const tempDesInfo = JSON.parse(JSON.stringify(designInfo));
            // Check if existing have some qty 
            if (!isCopyModelFromColorWise) {
                const existingQty = tempDesInfo.color_size_data[isShowCopyModal].size_info[0]?.quantity;
                if (!existingQty) {
                    toast.warn('You have not entered any qty.');
                } else {
                    tempDesInfo.color_size_data[isShowCopyModal].size_info = tempDesInfo.color_size_data[isShowCopyModal].size_info.map(si => {
                        si.quantity = parseFloat(existingQty) * 1;
                        return si;
                    });
                    setDesignInfo(tempDesInfo);
                }
            } else {
                const existingQty = tempDesInfo.color_size_data[0].size_info[0]?.quantity;
                if (!existingQty) {
                    toast.warn('You have not entered any qty.');
                } else {
                    tempDesInfo.color_size_data = tempDesInfo.color_size_data.map(csi => {
                        csi.size_info[0].quantity = parseFloat(existingQty) * 1;
                        return csi;
                    });
                    setDesignInfo(tempDesInfo);
                }
            }
        }
        setIsShowCopyModal(null);
    };

    const copyQuantityHandler = () => {
        const tempArr = selectedColorInfo[activeColorInex]?.size_info.filter(s => s.isSelected && s.is_stock_available && s.quantity > 0);
        if (tempArr.length) {
            setCopiedSizeQty(cloneDeep(tempArr))
        }
    }

    const pasteQuantityHandler = () => {
        // if(isPastedCopiedQty) return;

        if (Array.isArray(copiedSizeQty) && copiedSizeQty.length) {
            const newItemsFromCopy = [];
            const existingItems = cloneDeep(selectedColorInfo[activeColorInex]?.size_info);

            copiedSizeQty.forEach(copyItem => {
                const exitstingItem = existingItems.find(s => s.size_id === copyItem.size_id);
                if (exitstingItem) {
                   if(exitstingItem.is_stock_available) {
                       exitstingItem.quantity = copyItem.quantity;
                       exitstingItem.isSelected = true;
                    }
                } 
                
                // else {
                //     newItemsFromCopy.push({
                //         ...copyItem,
                //         ...existingItems[0],
                //         quantity: copyItem.quantity,
                //         size_name: copyItem.size_name,
                //         size_id: copyItem.size_id,
                //         is_stock_available: 1
                //     })
                // }
            });

            selectedColorInfo[activeColorInex].size_info = [...existingItems, ...newItemsFromCopy];
            setSelectedColorInfo([...selectedColorInfo]);
            setIsPastedCopiedQty(true);
        }
    }

    const onClearCopiedQty = () => {
        setCopiedSizeQty(null);
        setIsPastedCopiedQty(false);
    }

    const onClickSelectSize = () => {
        setIsShowSizesModal(true);
        setSizesModalSizeInfo(cloneDeep(selectedColorInfo[activeColorInex]?.size_info)); // secondry modal sizes
    }

    const OutOfStockTooltip = (data) => {
        const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props}>
              Out Of Stock
            </Tooltip>        );

        return <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
        >
            {data.children}
        </OverlayTrigger>;
    }


    const formData = (
        <form onSubmit={onSubmitHandler} >
            <div className="cart-head p-3 border-bottom">
                <div className="cart-proimg" style={{ padding: '45px 0px 0px 0px' }}>
                    <img
                        src={designInfo?.design_images && designInfo?.design_images?.length
                            ? designInfo.design_images[0] : placeHolderImages.DESIGN}
                        onError={(e) => { e.target.onerror = null; e.target.src = placeHolderImages.DESIGN; }} alt=""
                    />
                </div>
                <div className="cart-dt w-100 pe-0">
                    <div className="justify-content-between">
                        <div style={{ textAlign: 'end', margin: '-12px 0 15px 0' }}>
                            <a className="cart-save-size-btn-cancel" onClick={onHideHandler}>CANCEL</a>
                            <button type="submit" className="cart-save-size-btn" >SAVE</button>
                        </div>
                        <h5> <strong>{productQtyModelDesId?.designName || designInfo?.design_number || ""}</strong> </h5>
                    </div>
                    <p>{productQtyModelDesId?.categoryName || designInfo?.category_name || ""}</p>
                    <p>Rate: {orgInfo.store_json.show_rate ? designInfo?.sale_price || "N/A" : ""}</p>
                </div>
            </div>
            {designInfo && ( 1 || designInfo?.is_stock_available) ? (<>
                <div className="cart-data table-responsive">
                    <div className="heartpop"> <i className="fas fa-heart" style={{ fontSize: '22px', color: designInfo.is_saved_design ? 'tomato' : '#cacaca', padding: '14px' }}></i> </div>
                    {designInfo?.list_type == 2 || designInfo?.list_type == 4 ? <div className="cart-hed border-bottom">


                        <p className="ps-3 mb-2" style={{ fontSize: '14px', paddingTop:'20px' }}>SELECT COLORS</p>
                        <div className="cart-colors pl-0 px-3 d-flex flex-nowrap overflow-auto" style={{padding:'0 0 10px 0'}}>
                            {/* <li>{designInfo?.list_type == 1 ? "" : designInfo?.list_type == 2 ? "COLOR" : designInfo?.list_type == 3 ? "SIZE" : "COLOR / SIZE"}</li>
                             */}
                            {
                                designInfo?.list_type == 2 || designInfo?.list_type == 4 ?
                                    selectedColorInfo && selectedColorInfo.map((cs, csId) => {
                                        return (
                                            <span
                                                key={csId}
                                                className={`cart-color-item ${csId === activeColorInex ? 'card-qty-selected-color' : ''}`}
                                                onClick={() => onSelectColor(!cs.isSelected, csId)}
                                            >
                                                {cs.color_name}
                                            </span>
                                        )
                                    }) : null

                            }

                        </div>

                    </div> : null}
                    <div className="add-cart-sizes-container border-bottom">
                        {/* No Color No Size */}
                        {(designInfo?.list_type == 1 || designInfo?.list_type == 2) && selectedColorInfo[activeColorInex]?.size_info.length < 2 ?
                            <div className="cart-editbx">
                                <ul className="pl-0">
                                    <li className="prc">
                                        {orgInfo.store_json.show_rate ?
                                            <div className="pricbx mt-2">
                                                <i className={designInfo?.sale_price ? "fal fa-rupee-sign" : ""}></i> {designInfo?.sale_price || "N/A"}
                                            </div> : "N/A"
                                        }
                                    </li>
                                    <li className="quty">
                                        <div className="inbx">
                                            {
                                                selectedColorInfo[activeColorInex]?.size_info[0]?.is_stock_available ? (
                                                    <input
                                                        type="number" defaultValue={selectedColorInfo[activeColorInex]?.size_info[0]?.quantity || ""}
                                                        onChange={(event) => onChangeHandlerMain(event.target.value, 0)}
                                                        min={0} placeholder="Qty." className="form-control"
                                                        value={selectedColorInfo[activeColorInex]?.size_info[0]?.quantity || ""}
                                                    />
                                                ) : (
                                                    <div className="text-danger">Out Of Stock</div>
                                                )
                                            }
                                        </div>
                                    </li>
                                    <li className="epclone"></li>
                                </ul>
                            </div>
                            : null}

                        {/* Color- Size */}
                        {designInfo?.list_type > 2 || selectedColorInfo[activeColorInex]?.size_info.length > 1 ?
                            <div className="add-cart-sizes d-flex flex-nowrap overflow-auto bottom-border" style={{gap:'20px', padding:'15px 0 10px 15px'}} onClick={onClickSelectSize}>
                                {selectedColorInfo[activeColorInex]?.size_info?.map((size, index) => (<>
                                    <div key={index} className="add-cart-sizes-item">
                                        <div className="add-cart-sizes-item-size text-nowrap">
                                            <p className="m-0 text-center" style={{ fontWeight: 'bold' }}> {size.size_name || "N/A"}</p>

                                            {orgInfo.store_json.show_rate ?
                                                <div className="add-cart-sizes-item-pricbx">
                                                    <i className={size.size_rate ? "fal fa-rupee-sign" : ""}></i> {size.size_rate || "N/A"}
                                                </div> : ""
                                            }
                                        </div>
                                        <div className="add-cart-sizes-item-inbx">
                                            {size?.is_stock_available == 1 ? (
                                                <span className="add-cart-sizes-item-inbx-in form-control mx-auto">
                                                    {size.quantity || "0"}
                                                </span>
                                            ) : (<OutOfStockTooltip>
                                                <span className="add-cart-sizes-item-inbx-in form-control mx-auto border-danger text-danger" style={{ opacity: '', backgroundColor: 'rgb(255 225 225)' }}>
                                                    {size.quantity || "0"}
                                                </span>
                                            </OutOfStockTooltip>)
                                            }
                                        </div>
                                        <div className="add-cart-sizes-item-check mt-3 round-check">
                                            <input id="check-qty" type="checkbox" checked={size.quantity != 0} className="" />
                                            <label for="check-qty"></label>
                                        </div>
                                    </div>

                                </>)

                                )}


                            </div>
                            : null}
                        {/* With Color / Size / Both */}
                    </div>
                    <div className="d-flex justify-content-between p-3">
                        <div>
                            <strong className="border-end me-3 pe-3">QTY: {sizeTotalQty}</strong>
                            <strong className="amounttotal">Total Amount: {sizeTotalAmt}</strong>
                        </div>
                        <div>
                            {copiedSizeQty ? <span style={{ cursor: 'pointer' }} className="rounded-pill copy-qty-btn" onClick={pasteQuantityHandler}>PASTE</span> :
                                <span className="copy-qty-btn rounded-pill copybtn" onClick={copyQuantityHandler}> <img src="/images/ic_copy.svg" alt="" style={{display:'inline', width:'16px', margin:'0 4px 3px 0px' }}/>COPY QTY</span>
                            }
                        </div>
                    </div>

                    {/* copied items details */}
                    {copiedSizeQty ? <div className="copied-container d-flex justify-content-between  px-3  PY_2">
                        <div style={{ marginTop: '5px', overflow:'auto', display:'flex' }}>
                            {copiedSizeQty.map((c, index) => <span style={{ backgroundColor: 'white', padding: '4px 10px', borderRadius: '5px', marginRight:'5px' }} > {c.size_name}-{c.quantity}</span>)}
                        </div>
                        <div className="align-items-center d-flex" style={{ gap: '5px' }}>
                            <span className="p-1 rounded" style={{ background: '#84ddff6e;' }}>COPIED </span> |
                            <span onClick={onClearCopiedQty} style={{ cursor: 'pointer', color: 'red', backgroundColor: 'rgb(255 191 191)', padding: '4px 14px', borderRadius: '30px', fontSize: '15px' }} className="ms-1"> CLEAR</span>
                        </div>
                    </div> : ''}
                </div>

                {/* <div className="cart-actionbar">
                    <a className="btn-main calbtn cp" onClick={onHideHandler}>CANCEL</a>
                    <button type="submit" className="btn-main">SAVE</button>
                </div> */}
            </>
            )
                :
                <>
                    <div className="text-center text-danger" style={{ margin: '60px', }}>
                        <img src="/images/Outofstock.svg" alt="" style={{ width: '90px', margin: '50px auto 17px', opacity: '0.2' }} /> <p style={{ color: '#b8b8b8', fontWeight: '600' }} >Out Of Stock</p> </div>
                    {/* <div className="cart-actionbar">
                        <a className="btn-main calbtn cp" onClick={onHideHandler}>Done</a>
                    </div> */}
                </>
            }
        </form>
    );

    const saveSizesForm = (
        <div>
            <div className="cart-head border-bottom cart-head">
                <div className="cart-proimg" style={{padding:'40px 0 0 0' }}>
                    <img
                        src={designInfo?.design_images && designInfo?.design_images?.length ? designInfo.design_images[0] : placeHolderImages.DESIGN}
                        onError={(e) => { e.target.onerror = null; e.target.src = placeHolderImages.DESIGN; }} alt=""
                    />
                </div>
                <div className="cart-dt w-100 pe-0">
                    <div className="d-flex justify-content-between">
                        <div style={{ position: 'absolute', right: '14px' }}>
                            <span className="cart-save-size-btn-cancel" onClick={() => setIsShowSizesModal(false)}>CANCEL</span>
                            <span className="cart-save-size-btn" onClick={() => onSaveSizeModalHandler()} >SAVE QTY</span>
                        </div>
                        <h4>{productQtyModelDesId?.designName || designInfo?.design_number || ""}</h4>

                    </div>
                    <p>{productQtyModelDesId?.categoryName || designInfo?.category_name || ""}</p>
                    <p style={{display:'inline'}}>Rate: </p>{orgInfo.store_json.show_rate ? designInfo?.sale_price || "N/A" : ""}
                </div>
            </div>
            <div className="d-flex border-bottom">
                <div className="border-end w-50 text-center  PY_2">
                    <p style={{ margin: 'auto' }} >TOTAL QUANTITY</p>
                    <strong style={{ fontSize: '20px' }}>{sizeModalTotalQty}</strong>
                </div>
                <div className="w-50 text-center  PY_2">
                    <p style={{ margin: 'auto' }} >TOTAL AMOUNT</p>
                    <strong style={{ fontSize: '20px' }}>{sizeModalTotalAmt}</strong>
                </div>
            </div>
            {designInfo && (1 || designInfo?.is_stock_available) ? (<>
                <div className="cart-data table-responsive">
                    {/* Color- Size */}
                    {designInfo?.list_type > 2 ?
                        <div className="d-flex flex-column" style={{ overflowY: 'auto', height: '20rem', width: '100%' }}>
                            <div className="align-items-center border-top d-flex px-3 PY_2" style={{ background: isAllSelectSizesOption ? '#eceff3' : '' }}>
                                <div className="position-relative" style={{ width: '40%' }}>
                                    <span className="pe-2 round-check">
                                        <input
                                            type="checkbox" className="" checked={isAllSelectSizesOption}
                                            onChange={(e) => allSizeSelectionHandler(e.target.checked)}
                                            id="check-qty-1"
                                        />
                                        <label for="check-qty-1"></label>
                                    </span>
                                    <span className="m-0 text-center" style={{ fontWeight: 'bold' }}>SELECT ALL</span>
                                </div>
                                <div style={{ width: '60%' }} className="d-flex">
                                    <span style={{ fontSize: '10px', fontWeight: 600, width: '40%', margin: 'auto' }}>SET QTY FOR ALL</span>
                                    <div className="align-items-center d-flex justify-content-end" style={{ width: '60%' }}>
                                        {/* <span style={{fontSize:'20px', fontWeight: 'bold'}}>-</span> */}
                                        <button
                                            className="btn qty-change-btn" style={{ background: 'var(--blue)' }}
                                            onClick={() => onChangeHandler(allSelectSizesInpQty - 1)}
                                        >-</button>
                                        <input
                                            type="number" className="mx-1 text-center" style={{ width: '45px', height: '45px', backgroundColor: '#dbdbdb', border: 'none', borderRadius: '8px' }}
                                            value={allSelectSizesInpQty}
                                            onChange={(e) => onChangeHandler(e.target.value)}
                                        />
                                        <button
                                            className="btn qty-change-btn" style={{ background: 'var(--blue)' }}
                                            onClick={() => onChangeHandler(allSelectSizesInpQty + 1)}
                                        >+</button>
                                    </div>
                                </div>
                            </div>

                            {sizesModalSizeInfo?.map((size, index) => (<>
                                <div className="align-items-center border-top d-flex px-3  PY_2" style={{ background: size.isSelected ? '#eceff3' : '', pointerEvents: size.is_stock_available ? '': 'none' }}>
                                    <div className="position-relative" style={{ width: '30%' }}>
                                        <span className="pe-2 round-check">
                                            <input
                                                type="checkbox" checked={size.isSelected} className=""
                                                onChange={(e) => allSizeSelectionHandler(e.target.checked, index)}
                                                id={"check-qty" + index}
                                            />
                                            <label for={"check-qty" + index} style={{top: '3px'}}></label>
                                        </span>
                                        <span className="m-0 text-center" style={{ fontWeight: 'bold' }}>{size.size_name || "N/A"}</span>
                                    </div>
                                    <div style={{ width: '70%' }} className="d-flex" >
                                        <span className="text-danger pe-1" style={{ fontSize: '10px', fontWeight: 600, margin: 'auto', width: '30%' }}> {size.is_stock_available == 0 ? 'Out of Stock' : ''}</span>
                                        <span style={{ fontSize: '10px', fontWeight: 600, width: '30%', margin: 'auto' }}>
                                            {orgInfo.store_json.show_rate ?
                                                <div className="add-cart-sizes-item-pricbx">
                                                    Rate: {size.size_rate || designInfo?.sale_price || "N/A"}
                                                </div> : ""
                                            }
                                        </span>
                                        <div className="align-items-center d-flex justify-content-end" style={{ width: '70%' }}>
                                            {/* <span style={{fontSize:'20px', fontWeight: 'bold'}}>-</span> */}

                                            {size.is_stock_available ?
                                                (<>
                                                    <button className="btn qty-change-btn" style={{ background: 'var(--blue)' }} onClick={() => onChangeHandler(size.quantity - 1, index)}>-</button>
                                                    <input type="number" className="mx-1 text-center" style={{ width: '45px', height: '45px', backgroundColor: '#dbdbdb', border: 'none', borderRadius: '8px' }} value={size.quantity}
                                                        onChange={(e) => onChangeHandler(e.target.value, index)}
                                                    />
                                                    <button className="btn qty-change-btn" style={{ background: 'var(--blue)' }} onClick={() => onChangeHandler(size.quantity + 1, index)}>+</button>
                                                </>) :
                                                <OutOfStockTooltip key={index}>
                                                    <>
                                                        <button disabled className="btn qty-change-btn" style={{ background: 'var(--blue)' }} onClick={() => onChangeHandler(size.quantity - 1, index)}>-</button>
                                                        <input type="number" className="mx-1 text-center text-danger border-danger" style={{ width: '45px', height: '45px', backgroundColor: '#dbdbdb', border: 'none', borderRadius: '8px' }} value={size.quantity}
                                                            onChange={(e) => onChangeHandler(e.target.value, index)}
                                                        />
                                                        <button disabled className="btn qty-change-btn" style={{ background: 'var(--blue)' }} onClick={() => onChangeHandler(size.quantity + 1, index)}>+</button>
                                                    </>
                                                </OutOfStockTooltip>}

                                        </div>
                                    </div>
                                </div>
                            </>)
                            )}


                        </div>
                        : null}
                    {/* With Color / Size / Both */}

                </div>
            </>
            )
                :
                <>
                    <div className="text-center text-danger">
                        Out Of Stock
                    </div>
                    <div className="cart-actionbar">
                        <a className="btn-main calbtn cp" onClick={() => console.log('on hide-->>')}>Done</a>
                    </div>
                </>
            }
        </div>
    );

    console.log('select colors', selectedColorInfo);
    console.log('selectedSizesInfo', selectedSizesInfo);
    console.log('sizesModalSizeInfo', sizesModalSizeInfo);
    return (
        <>
            {
                productQtyModelDesId?.isFromMobile ?
                    <>
                        <div /* onClick={onHideHandler} */ className={((productQtyModelDesId && !loader && designInfo) ? "mobile-menu-open" : "") + " back-blur-fx add-clbx"}></div>
                        <div className={((productQtyModelDesId && !loader && designInfo) ? "mobile-menu-open" : "") + " modal-content add-clbx mobilecart-aside"} style={{ "background-color": "white" }}>
                            {formData}
                        </div>
                    </> :
                    <Modal className="background-blurbx" /* onHide={onHideHandler} */ show={(productQtyModelDesId && !loader && designInfo) ? true : false}>
                        {formData}
                    </Modal>

            }
            {
                <Modal className="background-blurbx"  /* onHide={onHideHandler} */ show={(productQtyModelDesId && !loader && isShowSizesModal) ? true : false}>
                    {saveSizesForm}
                </Modal>
            }
        </>

    );
};

export default ProductQtyModalNew;
